// import React from "react";

// const FormattedText = ({ text }) => {
//   // Split the text into individual bullet points
//   const bulletPoints = text.split("*");

//   // Remove the empty strings and trim the bullet points
//   const formattedBulletPoints = bulletPoints
//     .filter((point) => point.trim() !== "")
//     .map((point, index) => (
//       <li key={index} style={{ marginBottom: "8px" }}>
//         <span style={{ fontWeight: "bold" }}>• </span>
//         {point.trim()}
//       </li>
//     ));

//   return <ul>{formattedBulletPoints}</ul>;
// };

// export default FormattedText;
import React from "react";
import PropTypes from "prop-types";

const FormattedText = ({ text }) => {
  // Split the text into individual bullet points
  const bulletPoints = text.split("*");

  // Remove the empty strings and trim the bullet points
  const formattedBulletPoints = bulletPoints
    .filter((point) => point.trim() !== "")
    .map((point, index) => (
      <li key={index} style={{ marginBottom: "8px" }}>
        <span style={{ fontWeight: "bold" }}>• </span>
        {point.trim()}
      </li>
    ));

  return <ul>{formattedBulletPoints}</ul>;
};

// Prop types validation
FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FormattedText;
